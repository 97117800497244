import React from "react"
import { FaInstagram, FaFacebook, FaTwitter } from "react-icons/fa"
import { makeStyles } from "@material-ui/core/styles"
import Box from "@material-ui/core/Box"
import IconButton from "@material-ui/core/IconButton"


const useStyles = makeStyles(theme => ({
    icon: {
        color: theme.palette.grey[600],
    }
}))


const SocialMediaButton = ({ href, icon }) => {
    const classes = useStyles()
    return (
        <Box mr={2}>
            <IconButton
                href={href} 
                target="_blank" 
                className={classes.icon}
            >
                { icon }
            </IconButton>
        </Box>
    )
}

const SocialMedia = () => {
    return (
        <Box display="flex" position="absolute" bottom={24} right={24}>
            <SocialMediaButton
                href="https://www.instagram.com/swizzostudios/" 
                icon={<FaInstagram />} 
            />
            <SocialMediaButton
                href="#"
                icon={<FaFacebook />} 
            />
            <SocialMediaButton
                href="#"
                icon={<FaTwitter />}
            />
        </Box>
    )
}

export default SocialMedia
