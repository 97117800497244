import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"


const useStyles = makeStyles(theme => ({
    centered: {
        position: "fixed",
        top: "50%",
        transform: "translateY(-50%)",
    },
    image: {
        opacity: 0.1,
        height: "auto",
        width: "1500px",
    },
}))

const Headline = () => {
    const classes = useStyles()

    return (
        <Box className={classes.centered}>
            <Box>
                <Typography variant="h1">
                    Bring your ideas
                </Typography>
                <Typography variant="h1" color="primary">
                    to life.
                </Typography>
            </Box>
            <Box mt={2} width={400}>
                <Typography variant="body1" color="textSecondary">
                    We are a family of makers, designers, builders, and creators transforming your ideas into reality.
                </Typography>
            </Box>
            <Box mt={4}>
                <Button 
                    variant="contained" 
                    color="primary" 
                    disableElevation
                    href="/services"
                >
                    Our Services
                </Button>
            </Box>
        </Box>
    )
}

const Banner = () => {
    const classes = useStyles()
    return (
        <Box display="flex" className={classes.centered} width={1}>
            <img src="design_components.svg" className={classes.image} alt="Swizzo studios"/>
            <Headline />
        </Box>
    )
}

export default Banner
