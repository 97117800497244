import React from "react"
import Container from "@material-ui/core/Container"

import SEO from "../components/seo"
import Layout from "../components/layout"
import Banner from "../components/banner"
import SocialMedia from "../components/socialMedia"


const IndexPage = () => {
    return (
        <Layout>
            <SEO title="Home"/>
            <Container maxWidth="md" height={1}>
                <Banner />
                <SocialMedia />
            </Container>
        </Layout>
    )
}

export default IndexPage
